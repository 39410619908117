<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <span
      slot="id"
      slot-scope="text"
    >{{text}}</span>
    <span slot="price" slot-scope="record"> {{ record.price }}</span>
    <span slot="date" slot-scope="record"> {{ record.date }}</span>
    <span slot="transaction_status" slot-scope="record">{{ record.transaction_status }}</span>
    <span slot="pay_system" slot-scope="record">{{ record.pay_system }}</span>
    <span slot="balance" slot-scope="record"> {{ record.balance }}</span>
  </a-table>
</template>

<script>

export default {
  name: 'ClientsPaymentsTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return {}
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [],
    }
  },
  created() {
    this.setColumns()
  },
  methods: {
    setColumns() {
      const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Сумма',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: 'Дата',
          dataIndex: 'date',
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'transaction_status' },
        },
        {
          title: 'Платежная система',
          dataIndex: 'pay_system',
        },
        {
          title: 'Баланс',
          scopedSlots: { customRender: 'balance' },
        },
      ]
      this.columns = columns
    },
    toEditPage(record) {
      this.$router.push({ path: `/users/clients/${record.id}` })
    },
  },
  watch: {
    list: {
      handler() {
        this.setColumns()
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
